/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/Flaticon.eot");
	src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/Flaticon.woff") format("woff"),
	url("../../assets/fonts/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-right-chevron:before { content: "\f100"; }
.flaticon-left-chevron:before { content: "\f101"; }
.flaticon-timetable:before { content: "\f102"; }
.flaticon-flag:before { content: "\f103"; }
.flaticon-group:before { content: "\f104"; }
.flaticon-medal:before { content: "\f105"; }
.flaticon-target:before { content: "\f106"; }
.flaticon-left-quote:before { content: "\f107"; }
.flaticon-location:before { content: "\f108"; }
.flaticon-email:before { content: "\f109"; }
.flaticon-call:before { content: "\f10a"; }
.flaticon-down-arrow:before { content: "\f10b"; }
.flaticon-search:before { content: "\f10c"; }
.flaticon-cross-out:before { content: "\f10d"; }
.flaticon-menu:before { content: "\f10e"; }
.flaticon-insurance:before { content: "\f10f"; }
.flaticon-home-insurance:before { content: "\f110"; }
.flaticon-health-insurance:before { content: "\f111"; }
.flaticon-travel-insurance:before { content: "\f112"; }
.flaticon-car-insurance:before { content: "\f113"; }
.flaticon-life-insurance:before { content: "\f114"; }
.flaticon-agriculture:before { content: "\f115"; }
.flaticon-like:before { content: "\f116"; }
.flaticon-customer-service:before { content: "\f117"; }
.flaticon-care:before { content: "\f118"; }
.flaticon-team:before { content: "\f119"; }
.flaticon-policy:before { content: "\f11a"; }
.flaticon-education:before { content: "\f11b"; }
.flaticon-briefcase:before { content: "\f11c"; }
.flaticon-play-button:before { content: "\f11d"; }